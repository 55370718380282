<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="upfrontModal"
    :title="$route.name === 'finance-instant-payouts' ? $t('Update payout') : $t('Update Upfront')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="status" v-model="status" 
        v-validate="'required'" :placeholder="$t('Status') + '*'" label="name" :options="$route.name === 'finance-instant-payouts' ? payoutStatus : statuses"/>
        <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first('status') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input name="reference number" :label-placeholder="$t('Bank Reference Number') + '*'" v-model="upfront.bank_reference_number" class="mt-8 w-full"/>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-4">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="updateUpfront" class="active-btn btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluPrompt from '@/layouts/components/ShipBluPrompt.vue'

export default {
  props: ['upfrontModal', 'type', 'upfront', 'statements', 'accounts'],
  data () {
    return {
      statuses: [
        {
          name: 'Pending',
          value: 'pending'
        },
        {
          name: 'Deposited',
          value: 'deposited'
        },
        {
          name: 'Rejected',
          value: 'rejected'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      payoutStatus: [
        {
          name: 'Requested',
          value: 'requested'
        },
        {
          name: 'Deposited',
          value: 'deposited'
        },
        {
          name: 'Rejected',
          value: 'rejected'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      status: '',
      file: '',
      file_key: '',
      paymentType: 'cod',
      editable: false,
      merchants: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
    'upfront' () {
      this.status = this.upfront.status
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  },
  methods: {
    closeModal () {
      this.$emit('upfrontModal', false)
      this.$emit('loadPayments')
    },
    updateUpfront () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const upfront = {
            status: this.status.value,
            bank_reference_number: this.upfront.bank_reference_number
          }
          sendRequest(false, false, this, this.$route.name === 'finance-instant-payouts' ? `api/v1/instant/payouts/${this.upfront.id}/` : `api/v1/upfront/payouts/${this.upfront.id}/`, 'patch', upfront, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Payment'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('upfrontModal', false)
              this.$emit('loadUpfrontData')
            }
          )
        }
      })
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  }
}
</script>